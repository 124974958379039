import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';

const ExiliumGuidesAffinity: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page exilium-page'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Affinity & Dispatch</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_affinity.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Affinity & Dispatch</h1>
          <h2>
            Affinity & Dispatch Guide for Girls' Frontline 2: Exilium gacha
            game.
          </h2>
          <p>
            Last updated: <strong>19/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Affinity" />
        <p>
          Girls’ Frontline 2: Exilium features an Affinity system called
          <strong>“Implicit Feedback”</strong> that allows you to bond with
          T-Dolls and receive various rewards such as materials and cosmetics.
          Reaching Feedback Level 5 will grant you the option to form a special
          bond with the T-Doll called{' '}
          <strong>“Covenant” which is the game’s “Marriage” mechanic</strong>.
          Affinity Levels are also used to upgrade the{' '}
          <strong>Dispatch Room</strong> to provide passive stats and improve
          the production rewards.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_1.webp"
          alt="Guides"
        />
        <p>
          <strong>Implicit Feedback</strong> can be leveled in the Dormitory by
          clicking on the Gift option with the respective T-Doll. Gifts come in
          3 rarities: Gold, Purple, and Blue. T-Dolls have various preferences
          for non-chip gifts that are indicated at the top left with a colored
          smiley face. Pink smiley faces indicate the highest preference while
          Yellow indicates the lowest preference. Gifts can be obtained from
          Supply Mission, Neural Survey, and Dispatch Room.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_2.webp"
          alt="Guides"
        />
        <p>
          Implicit Feedback features 8 levels total; the first 5 levels provide
          passive stats for the respective T-Doll as well as various rewards.
          For levels 6 through 8 you will have to complete a special mission
          stage in order to progress to the next level. All 8 levels provide new
          Archive records which are personal stories about the T-Doll. Once the
          current level is maxed, you may use various materials needed to
          upgrade to the next level.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_3.webp"
          alt="Guides"
        />
        <p>
          <strong>Covenant</strong> is GFL2’s “Marriage” system that allows you
          to oath your T-Doll. Convent can only be formed at Implicit Feedback
          level 5 and requires 1 Covenant Ring.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_4.webp"
          alt="Guides"
        />
        <p>
          Once the Covenant is formed, your bonded T-Doll will permanently show
          a blue holographic circle above their shooting hand.{' '}
          <strong>
            Once you have reach bond level 8, it will upgrade to a gold
            holographic circle
          </strong>
          .
        </p>

        <SectionHeader title="Dispatch" />
        <StaticImage
          src="../../../images/exilium/generic/aff_5.webp"
          alt="Guides"
        />
        <p>
          <strong>Dispatch Room</strong> is a feature in GFL2 that allows
          offline production as well as resource conversion. By dispatching
          T-Dolls out onto missions, you may obtain various materials and
          Dispatch Records, which are the currency needed to purchase items in
          Dispatch Shop.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_6.webp"
          alt="Guides"
        />
        <p>
          Before T-Dolls can be dispatched, they must complete their{' '}
          <strong>Dispatch Assessment</strong> which contains 3 checklists.
          Every T-Doll also has their unique personality traits that provide
          bonuses to the missions they are attached to.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_7.webp"
          alt="Guides"
        />
        <p>
          Dispatch missions use the T-Doll Personalities to fulfill requirements
          for the mission to be dispatched. Both recommended Personalities must
          be fulfilled in order to obtain the bonus drop indicated by
          Personality Compatibility Rewards Section. Dispatch Missions take 20
          real hours to complete.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_8.webp"
          alt="Guides"
        />
        <p>
          Completing up to 25 dispatches per week will award Wishing Coins and
          Composite Materials. Wishing Coins are used for Mystery Boxes and
          Composite Materials are required to level the Dispatch Room.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_9.webp"
          alt="Guides"
        />
        <p>
          In order to upgrade the Dispatch Room, Implicit Feedback levels from
          T-Dolls are needed as well as Composite Levels. Once sufficient points
          are met, the dispatch center can then be upgraded to the next level.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_10.webp"
          alt="Guides"
        />
        <p>
          Dispatch Room upgrades provide passive stat boosts to Attack, Health
          and Defense. It also enhances various features of the Dispatch Room
          such as Intelligence Puzzle Storage Limit, Storage Conversion Rate,
          Extra Dispatch Logs and extra dispatch rewards from personality
          matching.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_11.webp"
          alt="Guides"
        />
        <p>
          Aside from T-Doll dispatch missions, Resource Materials can also be
          produced offline under Production of Resources. Upgrading the Dispatch
          Room will increase the production rate of the relevant materials.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_12.webp"
          alt="Guides"
        />
        <p>
          Any excess Resource Materials can be converted under Conversion of
          Resources to Wishing Coins.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/aff_13.webp"
          alt="Guides"
        />
        <p>
          Wishing Coin can be used in the Mystery Box, which provides various
          rewards given randomly. Mystery Box can be used up to 100 per week.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesAffinity;

export const Head: React.FC = () => (
  <Seo
    title="Affinity & Dispatch | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Affinity & Dispatch Guide for Girls' Frontline 2: Exilium gacha game."
    game="exilium"
  />
);
